import axios from "axios";
import { localStorageManager } from "./localStorageManager";

// Use environment-based domain configuration
const apiDomain =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_API_DOMAIN_PROD
          : process.env.REACT_APP_API_DOMAIN;


// Configure axios interceptors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.warn("Unauthorized - removing token");
      localStorageManager.removeItem("token");
    }
    return Promise.reject(error);
  }
);

// Helper function to set headers with token from localStorage
const createAuthHeaders = () => {
  const token = localStorageManager.getItem("token");
  return token ? { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` } : {};
};


// API call to check if validation is in progress
export const fetchEmailsProgress = () => {
  const headers = createAuthHeaders();
  if (!headers.Authorization) return Promise.reject(new Error("No token provided"));

  return axios.get(`${apiDomain}/api/ValidateEmails/getEmailsProgress`, { headers })
    .then(response => response.data)
    .catch((error) => {
      console.error("Validation status error:", error);
      throw error;
    });
};

export const deleteEmails = () => {
  const headers = createAuthHeaders();
  if (!headers.Authorization) return Promise.reject(new Error("No token provided"));

  //return axios.get(`${apiDomain}/api/ValidateEmails/deleteEmails`, { headers })
  //  .then(response => response.data)
  //  .catch((error) => {
  //    console.error("Validation status error:", error);
  //    throw error;
  //  });
};

// API call to validate emails by uploading a file
export const validateEmails = (file) => {
  if (!file) return Promise.reject(new Error("No file provided"));

  const headers = createAuthHeaders();
  if (!headers.Authorization) return Promise.reject(new Error("No token provided"));

  const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${apiDomain}/api/ValidateEmails`, formData, { headers })
    .then(response => response.data)
    .catch((error) => {
      console.error("File validation error:", error);
      throw error;
    });
};

// API call to log in a user
export const loginUser = (username, password) => {
  return axios.post(`${apiDomain}/api/Auth/login`, { username, password })
    .then(response => response.data)
    .catch((error) => {
      console.error("Login error:", error);
      throw error;
    });
};

// API call to download email validation results CSV
export const downloadValidationResults = () => {
  const headers = createAuthHeaders();
  if (!headers.Authorization) return Promise.reject(new Error("No token provided"));

  return axios
    .get(`${apiDomain}/api/ValidateEmails/download`, {
      headers,
      responseType: 'blob', // Important: This ensures we handle it as a file
    })
    .then((response) => {
      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'email_validation_results.csv'); // Specify the download filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("File download error:", error);
      throw error;
    });
};
