import React, { useEffect, useState } from "react";
import {
  validateEmails,
  fetchEmailsProgress,
  deleteEmails,
} from "../services/agent";
import { localStorageManager } from "../services/localStorageManager";
import FileInput from "./FileInput";
import DownloadCsvButton from "./DownloadCsv";

function EmailValidator() {
  const [file, setFile] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setErrorMessage("");
  };

  const handleValidate = () => {
    const token = localStorageManager.getItem("token");
    if (!file) {
      setErrorMessage("Please select a CSV file to validate.");
      return;
    }
    if (!token) {
      setErrorMessage("User authentication is required.");
      return;
    }

    setIsValidating(true);
    setErrorMessage("");
    setMessage("");

    validateEmails(file)
      .then((data) => {
        setMessage(
          "Validation started. Please check the progress on the status page."
        );
      })
      .catch(() => {
        setErrorMessage(
          "An error occurred while validating the emails. Please try again."
        );
      })
      .finally(() => {
        setIsValidating(false);
      });
  };

  useEffect(() => {
    fetchEmailsProgressData();
  }, []);

  const fetchEmailsProgressData = () => {
    const token = localStorageManager.getItem("token");
    if (token) {
      fetchEmailsProgress()
        .then((data) => {
          setIsValidating(data.emailsPending > 0 ? true : false);
        })
        .catch((error) => {
          console.error("Failed to fetch emails progress:", error);
        });
    }
  };

//  const deleteEmailsHandler = () => {
//    const token = localStorageManager.getItem("token");
//    if (token) {
//      deleteEmails()
//        .then((data) => {
//          setIsValidating(false);
//        })
//        .catch((error) => {
//          console.error("Failed to delete emails:", error);
//        });
//    }
//  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Email Validator</h2>
      <div className="card p-4 shadow">
        <div className="form-group">
          <label htmlFor="fileInput">
            Upload a CSV file containing emails:
          </label>
          <div className="d-flex align-items-center">
            <FileInput handleFileChange={handleFileChange} />
            {file && <span className="ms-3 text-muted">{file.name}</span>}
          </div>
        </div>

        {/* Display error messages */}
        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            <i className="bi bi-exclamation-triangle-fill me-2"></i>
            {errorMessage}
          </div>
        )}

        {/* Display informational messages */}
        {message && (
          <div className="alert alert-info mt-3" role="alert">
            <i className="bi bi-info-circle-fill me-2"></i>
            {message}
          </div>
        )}

        <button
          className="btn btn-primary mt-3"
          onClick={handleValidate}
          disabled={isValidating}
        >
          {isValidating ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Validating...
            </>
          ) : (
            <>
              Validate <i className="bi bi-play-circle-fill ms-1"></i>
            </>
          )}
        </button>

        {/* Download button */}
        <div className="card mt-4">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-6 mb-3 mb-md-0">
                <h5 className="card-title">Download Results</h5>
                <p className="card-text">
                  Download the validated emails as a CSV file.
                </p>
                <DownloadCsvButton />
              </div>
              <div className="col-md-6 text-md-end">
                <h5 className="card-title">Manage Emails</h5>
                <p className="card-text">
                  Delete emails that are currently in progress.
                </p>
                <button
                  className="btn btn-danger"
                >
                  <i className="bi bi-trash-fill me-2"></i>Delete In-Progress
                  Emails
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailValidator;
